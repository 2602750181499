import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AdBanner extends Component {

	constructor(props){
		super(props);
		this.state = {
			boolean: true
		}
	}

	componentDidMount() {
		this.asyncScript('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
		(window.adsbygoogle = window.adsbygoogle || []).push({});

		setInterval(() => { this.setState({boolean: !this.state.boolean}) }, 10000);
	}

	asyncScript = (scriptPath) => {

		const script = document.createElement('script');
		script.src = scriptPath;
		script.async = true;
		document.body.appendChild(script);

	}

	render() {
		const style = {
			display: 'block',
			width: "100%"
		};
		return (
			<Container>
				<div style={{ margin: 30 }} />
				<Row
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Col>
						{(this.state.boolean) ?
							<ins className={`adsbygoogle`}
								style={style}
								data-ad-client="ca-pub-4526018463078856"
								data-ad-slot="7181631420"
								data-ad-format="auto"
								data-full-width-responsive="true">
							</ins>
						:
							<ins className={`adsbygoogle`}
								style={style}
								data-ad-client="ca-pub-4526018463078856"
								data-ad-slot="7181631420"
								data-ad-format="auto"
								data-full-width-responsive="true">
							</ins>
						}

					</Col>
				</Row>
				<div style={{ marginTop: 50 }} />
			</Container>
			// <div></div>
		);
	}
}

export default AdBanner;