import React from "react";
import Layout from "../components/layout";
// import Image from '../components/image'
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Product from "../components/product";
import ReactGA from "react-ga";
import BlogComponent from "../components/blog"
import Hero from "../components/hero";
import DonationFooter from "../components/donation-footer";
import { graphql } from "gatsby";

class Blog extends React.Component {

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {

		return (
			<Layout>
				<SEO title="Blog" keywords={[`recommeded products`]} />
				<Navigation />

				<Hero
					header="Blog"
					subHeader="I have written a few blogs to specify instructions on how to solve common problems."
					maxWidth={650}
				/>

				<Container style={{ padding: 20, maxWidth: 500 }}>
					<Row>
						<Col>
							<Row>
								<BlogComponent
									name="Building a 360 Image Viewer Desktop App"
									decription="Creating a 360 Image Viewer with Electron.js, Three.js and Materialize"
									link="/blog/creating-360-image-viewer-electronjs-threejs-materialize"
								/>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<BlogComponent
									name="React.js: Displaying Google Ads without using external packages"
									decription="How to display Google Ads on your React.js Website"
									link="/blog/reactjs-displaying-google-ads-without-external-packages"
								/>
							</Row>
						</Col>
					</Row>
					{this.props.data.allMarkdownRemark.edges.map(({node}, index) => 
						<Row>
							<Col>
								<Row>
									<BlogComponent
										name={node.frontmatter.header}
										decription={node.frontmatter.subHeader}
										link={node.frontmatter.path}
									/>
								</Row>
							</Col>
						</Row>
					)}
				</Container>
				<DonationFooter ad={false}></DonationFooter>
			</Layout>
		);
	}
}

export default Blog;

export const GraphQLQuery = graphql`
  query GraphQLQuery {
	allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
	  edges {
		node {
		  frontmatter {
			path
			header
			subHeader
		  }
		}
	  }
	}
  }
`